import React from 'react';
import { 
  Comment
} from 'semantic-ui-react';

const Message = ({ message, isMyMsg }) => {
  return (
    <React.Fragment>
      { !isMyMsg && 
        <Comment>
          <Comment.Avatar as='a' src={message.user.imgLink} />
          <Comment.Content>
            <Comment.Author as='a'>{message.user.name}</Comment.Author>
            <div className="d-flex">
              <Comment.Text className="msg-text">
                {message.content}
              </Comment.Text>
              <div className="text-muted text-left msg-time">
                <small>{message.receiveDate}</small>
              </div>
            </div>
          </Comment.Content>
        </Comment>
      }
      { isMyMsg && 
        <Comment>
          <Comment.Content className="text-right">
          <Comment.Author as='a'>나({message.user.name})</Comment.Author>
            <div className="d-flex justify-content-end">
              <div className="msg-time text-right text-muted">
                <small>{message.receiveDate}</small>
              </div>
              <Comment.Text className="msg-text bg-sky">
                {message.content}
              </Comment.Text>
            </div>
          </Comment.Content>
        </Comment>
      }
    </React.Fragment>
  )
}

export default Message;
