export const isArrowRightCode = (code: string) => {
  return code === "ArrowRight";
};

export const isArrowLeftCode = (code: string) => {
  return code === "ArrowLeft";
};

export const isSpaceCode = (code: string) => {
  return code === "Space";
};
