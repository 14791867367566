import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ErrorBoundaryRoute from './shared/error-boundary-route';
import Gate from './pages/Gate';
import page404 from './shared/404';
import Header from './components/layout/Header';
import Omok from './pages/Omok';
import Wando from './pages/Wando';

const Routes = () => {
  return (
    <>
      <Header />
      <Switch>
        <Route path="/" component={Gate} exact />
        <Route path="/omok" component={Omok} exact />
        <Route path="/wando" component={Wando} exact />
        <ErrorBoundaryRoute component={page404} />
      </Switch>
    </>
  )
};

export default Routes;
