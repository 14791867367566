import React, { Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './assets/style/style.scss';
import 'semantic-ui-css/semantic.min.css'
import '../node_modules/bootstrap/scss/bootstrap.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './routes';
import ErrorBoundary from './shared/error-boundary';
import Loading from './shared/loading';
import { init, connect } from "./shared/reducers/socket";
import { createSocket, onConnect } from './shared/socket/socket';

let baseHref: string | undefined = document
  ?.querySelector('base')
  ?.getAttribute('href')
  ?.replace(/\/$/, '');


function App() {
  const dispatch = useDispatch();

  useEffect( () => {
    const socket = createSocket();
    dispatch(init(socket));
    onConnect(socket, (socketId) => {
      dispatch(connect(socketId));
    });
  }, []);

  return (
    <Router basename={baseHref}>
      <main className="app">
        <ErrorBoundary>
          <Suspense fallback={<Loading />}>
            <AppRoutes />
          </Suspense>
        </ErrorBoundary>
      </main>
    </Router>
  );
}

export default App;
