import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Button
} from 'semantic-ui-react';

const WandoIntro = ({ goNextStage }) => {
  return (
    <React.Fragment>
      <div className="text-center mt-3">
        <h1>감당할 수 있겠어요? 그의 인생을?</h1>
        <div className="mt-3">
          <Button
            content="감당해보겠다."
            icon="check"
            onClick={(event) => goNextStage(event)}
            primary
          />
          <Link to="/">
            <Button
              content="돌아간다."
              icon="sign-in alternate"
              className="ml10"
            />
          </Link>
        </div>
      </div>
    </React.Fragment>
  )
};

export default WandoIntro;