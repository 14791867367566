import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getGames } from '../shared/actions/game';
import { useTypedSelector } from '../shared/reducers';
import { Card, Loader  } from 'semantic-ui-react';
import { getUploadUrl } from '../shared/utils/commonUtils';

const Gate = ({ history }: any ) => {
  const dispatch = useDispatch();

  useEffect( () => {
    dispatch(getGames());
  }, []);

  const { games, status } = useTypedSelector(state => state.game);

  const getUseComment = (isUse: boolean) => {
    return isUse ? '운영중' : '준비중';
  };

  const historyPush = (game: any) => {
    if(!game.Isuse) return alert('해당 게임은 준비 중입니다.');
    history.push(game.Route);
  };

  return (
    <React.Fragment>
      { status === 'loading' && <Loader active size="medium">Loading</Loader>}
      <div className="row main">
        { games?.map( ( game: any) => {
          return ( 
            <div key={game.Gameid} className="col-lg-4 col-md-6 col-12 pt-3">
              <Card
                  image={getUploadUrl(game.Imglink)}
                  header={game.Name}
                  meta={getUseComment(game.Isuse)}
                  description={game.Comments}
                  className="m-auto pointer gate-card"
                  onClick={() => historyPush(game)}
              />
            </div> 
          )
        })}
      </div>
    </React.Fragment>
  );
};

export default Gate;
