import io from 'socket.io-client';
import config from '../../config/environment';
import { parseJson } from '../utils/commonUtils';
import { getStorage, setStorage } from '../utils/storage';

const USER_NAME_KEY = "username";

export const createSocket = () => {
  return io(config.socketURL, { transports: [ "websocket" ], withCredentials: true, query: {
    name: getStorage(USER_NAME_KEY, "")
  }});
}

export const onConnect = (socket, fn) => {
  socket.on("connect", (args: any) => {
    console.log("====== SOCKET CONNECTED ======");
    socket.on("send-id", (socketId: any) => {
      fn(socketId);
    });
  });
}

export const onDisconnect = (socket, fn) => {
  socket.on("disconnect", (args: any) => {
    console.log("====== SOCKET DISCONNECTED ======");
    fn(args);
  });
}

export const joinOmok = ({ socket, socketId, getUsers, getJoinedUser, leaveUser, getRooms, getNewRoom }) => {
  socket.on("send-omok-users", (users: any) => {
    const myUser = users?.filter( user => user.id === socketId) [0];
    if(myUser?.name) {
      setStorage(USER_NAME_KEY, myUser.name);
    }
    getUsers(users);
  });
  
  socket.on("send-omok-rooms", (rooms: any) => {
    getRooms(rooms);
  });
  
  socket.on("join-omok-user", (data: any) => {
    if(data.id === socketId) return;
    getJoinedUser(data);
  });
  
  socket.on("leave-omok", (socketId: string) => {
    leaveUser(socketId);
  });

  socket.on("make-omok-room", (data: any) => {
    getNewRoom(data);
  });

  socket.emit("join-omok", socketId);
}

export const leaveOmok = (socket) => {
  socket.emit("leave-omok");
}

export const sendOmokMessage = (socket: any, msg: string) => {
  socket.emit("omok-send-msg", msg);
}  

export const changeUserName = (socket:any, data: any) => {
  setStorage(USER_NAME_KEY, data.name);
  socket.emit("change-name", JSON.stringify(data));
}

export const changeOthersName = ({ socket, socketId, changeUserName }) => {
  socket.on("change-name", (data: any) => {
    if(data.id === socketId) return;
    changeUserName(data);
  });
}

export const receiveOmokMessage = ({ socket, receiveOmokMessage }) => {
  socket.on("omok-receive-msg", (args: any) => {
    receiveOmokMessage(args);
  });
}

export const offReceiveOmokMessage = (socket) => {
  socket.off("omok-receive-msg");
}

export const makeRoom = (socket, room) => {
  socket.emit("make-omok-room", JSON.stringify(room));
}
