import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getGames } from "../actions/game";

export interface Game {
  Gameid: number;
  Createdate: string;
  Workdate: string;
  Name: string;
  Imglink: string;
  Comments: string;
  Route: string;
  Isuse: number;
};

export interface GameState {
  status: 'idle' | 'loading' | 'failed';
  games: any;
  isError: boolean;
  errorStatus?: any;
}

const initialState: GameState = {
  status: 'idle',
  games: [],
  isError: false,
  errorStatus: {
    errorCode: '',
    errorMessage: ''
  }
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {},
  extraReducers: (builder) => { 
    return builder
      .addCase(getGames.pending, (state: GameState) => {
        state.status = 'loading';
      })
      .addCase(getGames.fulfilled, (state: GameState, action: any) => {
        state.status = 'idle';
        state.games = action.payload.data;
      })
      .addCase(getGames.rejected, (state: GameState, action: any) => {
        state.isError = true;
        state.status = 'failed';
        state.errorStatus = action.payload;
      })
  }
});

export default gameSlice;
