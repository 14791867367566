import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const avatarLinks = [
  'https://react.semantic-ui.com/images/avatar/small/rachel.png',
  'https://react.semantic-ui.com/images/avatar/small/lindsay.png',
  'https://react.semantic-ui.com/images/avatar/small/matt.jpg',
  'https://react.semantic-ui.com/images/avatar/small/joe.jpg'
];

export interface UserState {
  status: 'loading' | 'idle'
  users: any;
  currentImgIdx: number;
}

const initialState: UserState = {
  status: 'loading',
  users: [],
  currentImgIdx: 0
};

const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      const users = JSON.parse(JSON.stringify(action.payload));
      users.forEach( (user) => user.imgLink = avatarLinks [state.currentImgIdx++ % 4]);
      state.currentImgIdx = 0;
      state.users = users;
      state.status = 'idle';
    },
    addUser(state, action) {
      const user = action.payload;
      state.currentImgIdx = state.currentImgIdx > 2 ? 0 : state.currentImgIdx + 1;
      user.imgLink = avatarLinks [state.currentImgIdx];
      state.users = [ ...state.users, user ];
    },
    removeUser(state, action) {
      const idx = state.users.findIndex( user => user.id === action.payload);
      if(idx < 0) return;
      state.users = [ ...state.users.slice(0, idx), ...state.users.slice(idx + 1)];
    },
    changeName(state, action) {
      const idx = state.users.findIndex( user => user.id === action.payload.id);
      if(idx < 0) return;
      state.users[idx].name = action.payload.name;
    }
  },
  extraReducers: {}
});

export const { addUser, setUser, removeUser, changeName } = UserSlice.actions;
export default UserSlice;
