export const setStorage = (key, value) => {
  sessionStorage.setItem(key, value);
}

export const getStorage = (key, defaultValue) => {
  try {
    const value = sessionStorage.getItem(key);
    return value ? value : defaultValue;
  } catch(err) {
    return defaultValue;
  }
}