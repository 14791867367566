import React from 'react';
import { useTypedSelector } from '../shared/reducers';
import { 
  Header, 
  List,
  Segment,
  Button
} from 'semantic-ui-react';
import MakeRoomModal from './MakeRoomModal';
import { getRuleTypeText, getLimitTimeText } from '../shared/common/code';

const Room = ({ socket, socketId }) => {
  const { rooms, status } = useTypedSelector(state => state.room);
  return (
    <React.Fragment>
      <Header as='h3' dividing>
        방목록
      </Header>
      <Segment className="room-box">
        { !rooms?.length && 
          <div className="text-center">
            <small className="text-muted">방이 존재하지 않습니다.</small> 
          </div>
        }
        <List divided relaxed>
          { rooms?.map( (room: any) => {
            return (
                <List.Item key={room.id}>
                  <div className="d-flex justify-content-between">
                    <List.Content>
                      <List.Header>{room.name}</List.Header>
                        <small className="text-muted">
                          룰방식: {getRuleTypeText(room.ruleType)}
                          &nbsp;
                          |
                          &nbsp;
                          제한시간: {getLimitTimeText(room.limitTime)}
                          &nbsp;
                          |
                          &nbsp;
                          현재인원: {room.count} / {room.maxCount}
                        </small>
                    </List.Content>
                    <Button
                      content="입장"
                      icon="sign-in alternate"
                      size='tiny'
                      disabled={room.count === room.maxCount}
                      primary
                    />
                  </div>
                </List.Item>
            );
          })}
        </List>
      </Segment>
      <MakeRoomModal socket={socket} socketId={socketId} />    

    </React.Fragment>
  )
}

export default Room;
