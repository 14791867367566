import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import WandoCharacter from './WandoCharacter';
import WandoKeyBoard from './WandoKeyBoard';
import EventListener from 'react-event-listener';
import { isArrowRightCode, isArrowLeftCode, isSpaceCode } from '../../shared/utils/KeyboardUtils';
import SojuRain from './SojuRain';

const CLEAR = 'clear';
const OVER = 'over';
const ING = 'ing';

const WandoFirstStage = ({ goNextStage }) => {
  const maxScore = 200;
  const maxWidth = 400;
  const maxHeight = 500;

  const moveWidth = 10;
  const jumpSpeed = 100;

  const [ score, setScore ] = useState<number>(0);
  const [ wandoX, setWandoX ] = useState<number>(200);
  const [ wandoY, setWandoY ] = useState<number>(475);
  const [ isJump, setIsJump ] = useState<boolean>(false);
  const [ gameState, setGameState ] = useState<string>(ING);

  const wandoXRef = useRef(wandoX);
  const wandoYRef = useRef(wandoY);

  const scoreRef = useRef(score);

  const moveWando = (event: any) => {
    const code = event.code;
    if(isArrowRightCode(code) && !isBlockRight()) {
      const movedWandoX = wandoXRef.current + moveWidth;
      wandoXRef.current = movedWandoX;
      setWandoX(movedWandoX);
    }

    if(isArrowLeftCode(code) && !isBlockLeft()) {
      const movedWandoX = wandoXRef.current - moveWidth;
      wandoXRef.current = movedWandoX;
      setWandoX(movedWandoX);
    }
  };

  const jumpWando = (event: any) => {
    const code = event.code;
    if(!isSpaceCode(code) || isJump) return;

    const initY = wandoY;
    const gravity = 10;

    let currentSpeed = jumpSpeed;
    wandoYRef.current -= currentSpeed * 0.1;
    setIsJump(true);
    setWandoY(wandoYRef.current);

    const interval = setInterval(() => {
      currentSpeed -= gravity;
      wandoYRef.current -= currentSpeed * 0.1;
      setWandoY(wandoYRef.current);

      //점프 완료
      if(wandoYRef.current >= initY) {
        setWandoY(initY);
        clearInterval(interval);
        setIsJump(false);
        return;
      }
    }, 50);
  };

  const plusScore = () => {
    scoreRef.current = scoreRef.current + 1;
    setScore(scoreRef.current);
  };

  const overGame = () => {
    setGameState(OVER)
  };

  const clearGame = () => {
    setGameState(CLEAR);
  };
  
  const startGame = () => {
    scoreRef.current = 0;
    setScore(scoreRef.current);
    setGameState(ING);
  }

  const isBlockRight = () => {
    return wandoXRef.current + 10 >= maxWidth;
  };

  const isBlockLeft = () => {
    return wandoXRef.current - 20 <= 0;
  };

  const isGaming = () => {
    return gameState === ING;
  }

  const isClear = () => {
    return gameState === CLEAR;
  };

  const isGameOver = () => {
    return gameState === OVER;
  };

  return (
    <React.Fragment>
      <div className="text-center mt-3">
        <h1>때는 1993년 전설이 시작되었다.</h1>
        <div className="mt-3">
          <Link to="/">
            <Button
              content="홈으로"
              icon="sign-in alternate"
              className="ml10"
            />
          </Link>
        </div>
        <div className="wando-game-score">
          점수 : {score} / {maxScore}
        </div>
        <div className="mt-2 mb20">
          [키보드] 이동: 좌우 방향키, 점프: Space
        </div>
      </div>
      { isGaming() && 
        <>
          <SojuRain x={wandoX} y={wandoY} maxHeight={maxHeight} maxScore={maxScore} overGame={overGame} clearGame={clearGame} plusScore={plusScore} />
          <WandoCharacter x={wandoX} y={wandoY} width={maxWidth} height={maxHeight} />
          <WandoKeyBoard onKeyDownArrow={moveWando} onKeyUpSpace={jumpWando} />
          <EventListener target={document} onKeyDown={moveWando} onKeyUp={jumpWando} />
        </>
      }
      { isClear() && 
        <div className="wando-frame">
            <div>
              <div>
                Clear!
                <br />
                ㅎㅎㅎ 봐줬다..
                {/* <br />
                때는 2021년 D 씨와 J 씨는 회사에서 회식을 하다가 서로 눈이 맞아 계단에서...
                <br />
                <img src="/img/history1.png" width={100} height={200}/> */}
              </div>
              <Button 
                className="mt-5"
                content="다시 하기"
                onClick={startGame}
              />
            </div>
        </div>
      }
      { isGameOver() &&
        <div className="wando-frame">
          <div>
            <div>
              <h2>GAME OVER!</h2>
                <br />
                그런 어줍잖은 각오로는 
                <br />
                완도의 삶에 다가갈 수 없습니다.
                <br />
                다시 도전하세요.
            </div>
            <Button 
                className="mt-5"
                content="다시 하기"
                onClick={startGame}
              />
          </div>
        </div>
      }
    </React.Fragment>
  )
};

export default WandoFirstStage;
