import React, { useState } from 'react';
import { 
  Input
} from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { changeName } from '../shared/reducers/user';
import { changeUserName } from '../shared/socket/socket';

const UserInput = ({ socket }) => {
  const dispatch = useDispatch();
  const [ value, setValue ] = useState('');

  const changeValue = () => {
    if(!value?.trim()) return alert('값를 입력해주세요.');
    dispatch(changeName({
      id: socket.id,
      name: value
    }))
    setValue('');
    //socket 전송
    changeUserName(socket, {
      id: socket.id,
      name: value
    })
  };

  const onKeyPress = (event) => {
    if(event.code !== 'Enter') return;
    changeValue();
  }

  return (
    <React.Fragment>
      <Input action={{
        content: '이름변경', 
        onClick: () => {
          changeValue();
        }
      }} placeholder='Name' value={value} className="w-100" onKeyPress={onKeyPress} onChange={(event, data) => setValue(data.value)}/>
    </React.Fragment>
  )
}

export default UserInput;
