import React from 'react'
import { Route, RouteProps } from 'react-router-dom'
import ErrorBoundary from './error-boundary'

const ErrorBoundaryRoute = (props: RouteProps) => {
  return (
    <ErrorBoundary key={props.location?.pathname}>
      <Route {...props} />
    </ErrorBoundary>
  )
}

export default ErrorBoundaryRoute
