import React from 'react';
import { useTypedSelector } from '../shared/reducers';
import { 
  Header, 
  Segment, 
  List, 
  Image 
} from 'semantic-ui-react';
import UserInput from '../components/UserInput';


const Users = ({ socket, socketId }) => {
  const { users, status } = useTypedSelector(state => state.user);

  return (
    <React.Fragment>
      <Header as='h3' dividing>
        유저목록
        { users.length > 0 && <small className="text-muted ml5 d-inline-block">[ {users.length} 명 ]</small> }
      </Header>
      <Segment className="user-box mb-0">
        { !users?.length && 
          <div className="text-center">
            <small className="text-muted">유저가 없습니다.</small> 
          </div>
        }
        { users?.map( (user: any ) => {
          return ( <List divided relaxed key={user.id}>
            <List.Item>
              <Image avatar src={user.imgLink}/>
              <List.Content>
              <List.Header as='a'>{user.name}</List.Header>
                <List.Description>
                  { user.id === socketId && '나' }
                  { user.id !== socketId && 'GUEST' }
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
          )
        })}
      </Segment>
      { status === 'idle' && 
        <UserInput socket={socket} />
      }
    </React.Fragment>
  )
}

export default Users;
