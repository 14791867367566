import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../shared/reducers';
import { 
  Message, 
} from 'semantic-ui-react';

import { joinOmok, leaveOmok, changeOthersName } from '../shared/socket/socket';
import { addUser, setUser, removeUser, changeName } from '../shared/reducers/user';
import { setRoom, addRoom } from '../shared/reducers/room';
import Users from '../components/Users';
import Chat from '../components/Chat';
import Room from '../components/Room';



const Omok = () => {
  const dispatch = useDispatch();
  const { socket, status, socketId } = useTypedSelector(state => state.socket);


  if(status === 'connected') {
    joinOmok({
      socket,
      socketId,
      getUsers(users: any) {
        if(!users?.length) return;
        dispatch(setUser(users));
      },
      getJoinedUser(user: any) {
        if(!user) return;
        dispatch(addUser(user));
      },
      leaveUser(socketId: any) {
        if(!socketId) return;
        dispatch(removeUser(socketId));
      },
      getRooms(rooms: any) {
        if(!rooms?.length) return;
        dispatch(setRoom(rooms));
      },
      getNewRoom(room: any) {
        dispatch(addRoom(room));
      }
    });
    changeOthersName({
      socket,
      socketId,
      changeUserName(data: any) {
        dispatch(changeName(data));
      }
    })
  }

  useEffect( () => {
    return () => {
      if(!socket) return;
      leaveOmok(socket);
    }
  }, [ socket ]);

  return (
    <React.Fragment>
      <div className="mb-5">
        <Message positive className="game-title mt-4">
          오목
        </Message>
        <div className="row main">
          <div className="col-sm-6 col-lg-8 mt-4">
           <Room socket={socket} socketId={socketId} />
          </div>
          <div className="col-sm-6 col-lg-4 mt-4">
            <Users socket={socket} socketId={socketId} />  
            <Chat socket={socket} socketId={socketId} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Omok;
