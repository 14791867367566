export const limitTimes = [
  { key: 60, text: '1분', value: 60 },
  { key: 180, text: '3분', value: 180 },
  { key: 300, text: '5분', value: 300 },
];

export const ruleTypes = [
  { key: 'DEFAULT', text: '일반룰', comment: '흑백 모두 3-3, 장목 금수' }
];

export const getRuleTypeText = (key) => {
  return ruleTypes.filter(type => type.key === key) [0]?.text;
}

export const getLimitTimeText = (key) => {
  return limitTimes.filter( time => time.key === key) [0]?.text;
}