import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTypedSelector } from '../shared/reducers';
import ChatRoom from '../components/ChatRoom';
import { receiveOmokMessage, offReceiveOmokMessage } from '../shared/socket/socket';
import { useDispatch } from 'react-redux';
import { addMessage } from '../shared/reducers/chat';
import moment from 'moment';

const Chat = ({ socket, socketId }) => {
  const dispatch = useDispatch();
  const { users, status } = useTypedSelector(state => state.user);

  let curUser = null
  if(users?.length > 0) {
    curUser = users.filter( user => user.id === socketId ) [0];
  }

  useEffect(() => {
    if(!socket || !users.length) return;
    receiveOmokMessage({
      socket,
      receiveOmokMessage(message) {
        if(message.Id === socketId) return;
        const user = users.filter( user => user.id === message.Id) [0];
        if(!user) return;
        dispatch(addMessage({
          id: message.Id,
          user: user,
          content: message.msg,
          receiveDate: moment(message.time).format('h:mm a')
        }));
      }
     });
     return () => {
       offReceiveOmokMessage(socket);
     }
  }, [socket, users]);

  return (
    <React.Fragment>
      <ChatRoom  socket={socket} socketId={socketId} curUser={curUser} status={status} />
    </React.Fragment>
  )
}

export default Chat;
