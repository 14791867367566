import { createAsyncThunk } from '@reduxjs/toolkit';
import client from '../api/client';
import { GET_GAME_ALL_URL } from '../api/url';

export const getGames = createAsyncThunk ('game', async (args, { rejectWithValue }) => {
  try {
    return await client.get(GET_GAME_ALL_URL);

  } catch(err: any) {
    return rejectWithValue(err.response);
  }
});
