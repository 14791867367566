import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './config/store';


const rootEl = document.getElementById('root');

const render = (Component: any) => {
  ReactDOM.render(
    <Provider store={store}>
        <Component />
    </Provider>,
    rootEl
  );
}

render(App);
