import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SocketState {
  status: 'connected' | 'disconnected';
  socket: any;
  socketId: string | null;
}

const initialState: SocketState = {
  status: 'disconnected',
  socket: null,
  socketId: null
};

const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    init(state, action) {
      const socket = action.payload;
      state.socket = socket;
    },
    connect(state, action) {
      state.status = 'connected';
      state.socketId = action.payload;
    }
  },
  extraReducers: {}
});

export const { init, connect } = socketSlice.actions;
export default socketSlice;
