import React, { useRef, useState } from 'react';
import { 
  Input
} from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { addMessage } from '../shared/reducers/chat';
import { setConstantValue } from 'typescript';
import { sendOmokMessage } from '../shared/socket/socket';
import moment from 'moment';

const ChatInput = ({ socket, curUser }) => {
  const dispatch = useDispatch();
  const [ value, setValue ] = useState('');
  const inputRef: any = useRef();

  const sendMessage = () => {
    if(!value?.trim()) return alert('메세지를 입력해주세요.');
    dispatch(addMessage({
      user: curUser, 
      content: value,
      receiveDate: moment().format('h:mm a')
    }));
    setValue('');
    inputRef.current.focus();
    //socket 전송
    sendOmokMessage(socket, value);
  };

  const onKeyPress = (event) => {
    if(event.code !== 'Enter') return;
    sendMessage();
  }

  return (
    <React.Fragment>
      <Input action={{
        content: '전송', 
        onClick: () => {
          sendMessage();
        }
      }} placeholder='Comments...' ref={inputRef} value={value} className="w-100" onKeyPress={onKeyPress} onChange={(event, data) => setValue(data.value)}/>
    </React.Fragment>
  )
}

export default ChatInput;
