import config from '../../config/environment';

export const getUploadUrl = (url: string) => {
  return `${config.uploadURL}/${url}`;
}

export const parseJson = (data: any) => {
  let json = null;
  try {
    json = JSON.parse(data);
  } catch(err) {}
  return json;
}

export const debounce = (callback, debounceTime) => {
  let timer: any = null;
  return function executeFunction(event: any) {
    if(timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      callback(event);
    }, debounceTime);
  }
}
