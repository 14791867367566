import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight, faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons';

const WandoKeyBoard = ({ onKeyDownArrow, onKeyUpSpace }) => {
  const [ curInterval, setCurInterval ] = useState<NodeJS.Timeout | null> (null);

  const onKeyDownArrowLeft = () => {
    _onKeyDownArrow('ArrowLeft');
  };

  const onKeyDonwArrowRight = () => {
    _onKeyDownArrow('ArrowRight');
  };

  const _onKeyDownArrow = (code: string) => {
    if(curInterval) {
      clearInterval(curInterval);
    }

    const interval = setInterval(() => {
      onKeyDownArrow({ code: code });
    }, 50);

    setCurInterval(interval);
  }

  const onKeyDownArrowUp = () => {
    onKeyUpSpace({ code: 'Space' });
  };

  useEffect( () => {
    return () => { 
      if(curInterval)
        clearInterval(curInterval) 
    };
  }, []);

  return (
    <React.Fragment>
      <div className="wando-keyboard">
        <div>
          <FontAwesomeIcon icon={faArrowAltCircleLeft} size={'4x'} className="opacity05" onClick={onKeyDownArrowLeft} />
          <FontAwesomeIcon icon={faArrowAltCircleRight} size={'4x'} className="ml20 opacity05" onClick={onKeyDonwArrowRight} />
          <FontAwesomeIcon icon={faArrowAltCircleUp} size={'4x'} className="ml40 opacity05" onClick={onKeyDownArrowUp} />
        </div>
      </div>
    </React.Fragment>
  )
};

export default WandoKeyBoard;