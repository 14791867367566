export const roundedRect = (context, options) => {
  if(!context) return;
  context.beginPath();
  context.strokeStyle = options.color;
  context.fillStyle = options.color;
  context.lineJoin = "round";
  context.lineWidth = options.radius;

  context.strokeRect(
      options.x + (options.radius * .5),
      options.y + (options.radius * .5),
      options.width - options.radius,
      options.height - options.radius
  );

  context.fillRect(
      options.x + (options.radius * .5),
      options.y + (options.radius * .5),
      options.width - options.radius,
      options.height - options.radius
  );

  context.stroke();
  context.fill();
};