import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Room {
  id: string;
  name: string;
  user: string;
  ruleType: string;
  limitTime: string;
  maxCount: string;
  count: number;
  createDt: number;
}

export interface RoomState {
  status: string;
  rooms: Array<Room>;
}

const initialState: RoomState = {
  status: 'loading',
  rooms: [],
};

const RoomSlice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    setRoom(state, action) {
      const rooms = JSON.parse(JSON.stringify(action.payload));
      state.rooms = rooms?.sort((a: Room, b: Room) => {
        return b.createDt - a.createDt;
      });
      state.status = 'idle';
    },
    addRoom(state, action) {
      const room = action.payload;
      state.rooms = [ room, ...state.rooms ];
    }
  },
  extraReducers: {}
});

export const { setRoom, addRoom } = RoomSlice.actions;
export default RoomSlice;
