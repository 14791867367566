import React from 'react'
import { createBrowserHistory } from 'history'
import { Button } from 'semantic-ui-react';

interface IErrorBoundaryProps {
  readonly children: JSX.Element | JSX.Element[]
}

interface IErrorBoundaryState {
  readonly error: any
  readonly errorInfo: any
}

class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  readonly state: IErrorBoundaryState = {
    error: undefined,
    errorInfo: undefined,
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({
      error,
      errorInfo,
    })
  }

  onClose() {
    const history = createBrowserHistory()
    history.goBack()
  }

  render() {
    const { error, errorInfo } = this.state

    if (errorInfo) {
      const errorDetails =
        process.env.NODE_ENV === 'development' ? (
          <details className="preserve-space">
            {error && error.toString()}
            <br />
            {errorInfo.componentStack}
          </details>
        ) : undefined
      return (
        <>
          <div>
            <p>예기치 않은 오류가 발생했습니다.</p>
            <p>불편을 드려 죄송합니다.</p>
          </div>
          <div>
            <Button primary onClick={this.onClose}>이전 페이지로</Button>
          </div>
          <div className="errorboundary-detail">{errorDetails}</div>
        </>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
