import React, { useEffect, useRef, useState } from 'react';

interface Soju {
  id: number;
  x: number;
  y: number;
  isEnd: boolean;
}

const SojuRain = ({ x, y, maxHeight, maxScore, overGame, clearGame, plusScore }) => {
  const maxWidth = 370;
  const sojuHeight = 60;
  const sojuWidht = 20;
  const startY = 10;
  const [ sojus, setSojus ] = useState<Soju[]>([]);
  const [ count, setCount ] = useState<number>(1);
  const [ isGameOver, setIsGameOver ] = useState<boolean>(false);

  const xRef = useRef(x);
  const yRef = useRef(y);
  const sojusRef = useRef(sojus);
  const countRef = useRef(count);
  const isGameOverRef = useRef(isGameOver);

  const addSoju = () => {
    const interval = setInterval(() => {
      if (countRef.current > maxScore || isGameOverRef.current) {
        return clearInterval(interval);
      }

      const soju = { id: count, x: getRandomX(), y: startY, isEnd: false };
      const nextSojus = [ ...sojusRef.current, soju ];
      const nextCount = countRef.current + 1;

      sojusRef.current = nextSojus;
      countRef.current = nextCount;

      setCount(nextCount);
      rainSoju(soju);
    }, 500);
  };

  const rainSoju = (soju: Soju) => {
    const interval = setInterval(() => {
      soju.y += 5;
      checkGameOver(soju);

      if(isGameOverRef.current) {
        return clearInterval(interval);
      }

      if (soju.y + sojuHeight >= maxHeight) {
        reachFloorSoju(soju, interval);
        if(isDepleteSoju()) {
          return clearGame();
        }
      }
      setSojus([ ...sojusRef.current ]);
    }, 10);
  };

  const reachFloorSoju = (soju: Soju, interval: NodeJS.Timeout) => {
    clearInterval(interval);
    plusScore();
    soju.isEnd = true;
  };

  const checkGameOver = (soju: Soju) => {
    if(Math.abs(xRef.current - soju.x) < 30 && Math.abs(yRef.current - soju.y) < 50) {
      isGameOverRef.current = true;
      setIsGameOver(isGameOverRef.current);
      overGame();
    }
  };

  const isDepleteSoju = () => {
    const endSojuLength = sojusRef.current.filter(soju => soju.isEnd).length;
    return endSojuLength === maxScore;
  };

  const getRandomX = () => {
    return Math.floor(Math.random() * maxWidth) + 10;
  };

  useEffect(() => {
    addSoju();
  }, []);

  useEffect(() => {
    xRef.current = x;
    yRef.current = y;
  }, [ x, y]);

  return (
    <React.Fragment>
      <div className="soju-rain-frame">
        { sojus.map( (soju, idx) => {
          if(soju.isEnd) return;
          return (
            <img key={idx} src="/img/soju.png" alt="소주" width={sojuWidht} height={sojuHeight} className="position-absolute" style={{ left: soju.x, top: soju.y }}/>
          )
        })}
      </div>
    </React.Fragment>
  )
};

export default SojuRain;