import React, { useCallback, useEffect, useRef } from 'react';
import { useTypedSelector } from '../shared/reducers';
import { 
  Header, 
  Comment,
  Loader
} from 'semantic-ui-react';
import Message from './Message';
import ChatInput from './ChatInput';

const ChatRoom = ({ socket, socketId, curUser, status }) => {
  const { messages } = useTypedSelector(state => state.chat);
  const scrollRef:any = useRef();

  const scrollToBottom = useCallback( () => {
    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  }, [ messages ]);

  useEffect(() => {
    scrollToBottom();
  }, [ scrollToBottom ]);

  return (
    <React.Fragment>
      <Header as='h3' dividing>
        채팅
      </Header>
      { status === 'loading' && <Loader active size="medium">Loading</Loader>}
      <div ref={scrollRef} className="border p-3 chat-box">
        <Comment.Group size='mini'>
          { messages?.map( (message: any, idx: number) => {
            return (
              <Message key={`msg-${idx}`} message={message} isMyMsg={message.user.id === socketId} />
            )
          })}
        </Comment.Group>
      </div>
      { status === 'idle' && 
        <ChatInput socket={socket} curUser={curUser}/>
      }

    </React.Fragment>
  )
}

export default ChatRoom;
