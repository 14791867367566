import axios from 'axios';
import config from '../../config/environment';

const client = axios.create({
  withCredentials: true
});

client.defaults.baseURL = `${config.baseURL}/api`;
client.defaults.headers = {
  'Content-Type': 'application/json'
}

export default client;
