import React, { useEffect, useState } from 'react';
import { roundedRect } from '../../shared/utils/canvasUtils';

interface CanvasParam { 
  x: number; 
  y: number; 
  width: number; 
  height: number; 
};

const WandoCharacter = ({ x, y, width, height }: CanvasParam) => {
  const elementId = "wandoCanvas";
  const DOUBLE_PI = Math.PI * 2;

  const [ context, setContext ] = useState<CanvasRenderingContext2D | null> (null);

  useEffect(() => {
    if(!context) {
      setCanvasContext();
      return;
    }
    clearCanvas();
    renderWando();
  }, [ context, x, y ]);
  
  const setCanvasContext = () => {
    const canvas = document.getElementById(elementId) as HTMLCanvasElement;
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    context.canvas.width = width;
    context.canvas.height = height;
    setContext(context);
  };

  const clearCanvas = () => {
    if(!context) return;
    context.clearRect(0, 0, width, height);
  }

  const renderWando = () => {
    drawBody();
    drawEyes();
  };

  const drawBody = () => {
    roundedRect(context, {
      x: x - 20,
      y: y - 20,
      width: 30,
      height: 40,
      radius: 15,
      color: '#6435c9'
    })
  };

  const drawEyes = () => {
    drawWhiteEyes();
    drawBlackEyes();
  };

  const drawWhiteEyes = () => {
    if(!context) return;
    context.beginPath();
    context.arc(x - 14, y - 13, 5, 0, DOUBLE_PI, false);
    context.arc(x + 4, y - 13, 5, 0, DOUBLE_PI, false);
    context.fillStyle = '#fff';
    context.fill();
  };

  const drawBlackEyes = () => {
    if(!context) return;
    context.beginPath();
    context.arc(x - 14, y - 15, 2, 0, DOUBLE_PI, false);
    context.arc(x + 4, y - 15, 2, 0, DOUBLE_PI, false);
    context.fillStyle = '#200e09';
    context.fill();
  };

  return (
    <React.Fragment>
      <canvas id={elementId} className="wando-canvas" />
    </React.Fragment>
  )
};

export default WandoCharacter;
