import React from 'react';
import { Link } from 'react-router-dom';
import {
  Segment,
  Image,
  Sticky
} from 'semantic-ui-react';

function Header() {
  return (
    <header>
      <Sticky>
        <Segment textAlign="center" color="violet">
          <Link to="/">
            <Image src="/img/logo.png" size="small" className="logo" />
          </Link>
        </Segment>
      </Sticky>
    </header>
  )
}

export default Header;
