import { TypedUseSelectorHook, useSelector } from "react-redux";
import { combineReducers } from "redux";
import gameSlice from "./game";
import UserSlice from "./user";
import socketSlice from "./socket";
import ChatSlice from "./chat";
import RoomSlice from "./room";

const rootReducer = combineReducers({
  game: gameSlice.reducer,
  socket: socketSlice.reducer,
  user: UserSlice.reducer,
  chat: ChatSlice.reducer,
  room: RoomSlice.reducer
});

export type RootState = ReturnType<typeof rootReducer>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export default rootReducer;
