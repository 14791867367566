import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Message {
  user: any;
  content: string;
  receiveDate?: any;
}

export interface ChatState {
  messages: Array<Message>
}

const initialState: ChatState = {
  messages: [],
};

const ChatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addMessage(state, action) {
      state.messages = [ ...state.messages, action.payload ];
    }
  },
  extraReducers: {}
});

export const { addMessage } = ChatSlice.actions;
export default ChatSlice;
