import React, { useEffect, useRef, useState } from 'react';
import { 
  Modal,
  Button,
  Form,
  Select,
  Radio,
  Message,
  Input
} from 'semantic-ui-react';
import client from '../shared/api/client';
import { GET_UUID } from '../shared/api/url';
import { makeRoom } from '../shared/socket/socket';
import { limitTimes, ruleTypes } from '../shared/common/code';
import moment from 'moment';

const MakeRoomModal = ({ socket, socketId }) => {
 
  const [ open, setOpen ] = useState(false);
  const [ ruleType, setRuleType ] = useState('DEFAULT');
  const [ limitTime, setLimitTime ] = useState(60);
  const [ roomName, setRoomName ] = useState('');
  const [ isError, setIsError ] = useState(false);

  const getUUID = async () => {
    return await client.get(GET_UUID);
  }

  const initValue = () => {
    setRoomName('');
    setRuleType('DEFAULT');
    setLimitTime(60);
    setIsError(false);
  }

  const makeRoomInfo = () => {
    if(!roomName || !roomName.trim()) {
      setIsError(true);
      return;
    }
    getUUID().then( (res: any) => {
      if(!res.data || !res.data.result) return;
      makeRoom(socket, {
        id: res.data.result,
        user: socketId,
        name: roomName,
        ruleType: ruleType,
        limitTime: limitTime,
        maxCount: 2,
        count: 1,
        createDt: moment().toDate().getTime()
      });
      //오목 방으로 이동
      initValue();
      setOpen(false);
    });
  };

  return (
    <React.Fragment>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<Button content='방 만들기' primary className="w-100" />}
        dimmer="blurring"
        size="small"
      >
        <Modal.Header>방 만들기</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field
              error={isError && (!roomName || !roomName.trim()) ? { content: '이름을 입력해주세요.' } : false} 
              label="방이름"
              control={Input}
              value={roomName}
              onChange={(e: any, data: any) => setRoomName(data.value)}
              placeholder="Name"
            />
            <Form.Field
              control={Select}
              label='제한시간'
              options={limitTimes}
              placeholder='제한시간'
              value={limitTime}
              onChange={(e, data) => { 
                setLimitTime(data.value)
              }}
            />
            <Form.Group grouped>
              <label>룰 종류</label>
              { ruleTypes.map( (rt: any) => {
                return (
                  <Form.Field
                    key={rt.key}
                    control={Radio}
                    label={rt.text}
                    value={rt.key}
                    checked={ruleType === rt.key}
                    onChange={(e, data) => setRuleType(data.value)}
                  />
                )
              })}
            </Form.Group>
            { ruleTypes.filter( rt => rt.key === ruleType).map( rt => {
              return (
                <Message
                  info
                  key={rt.key}
                  header={rt.text}
                  content={rt.comment}
                />
              )
            })}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="취소"
            labelPosition='left'
            icon='times'
            onClick={() => setOpen(false)}
          />
          <Button
            content="생성"
            labelPosition='left'
            icon='checkmark'
            onClick={() => makeRoomInfo()}
            positive
          />
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  )
}

export default MakeRoomModal;
