import React, { useEffect, useState } from 'react';
import WandoIntro from '../components/wando/WandoIntro';
import WandoFirstStage from '../components/wando/WandoFirstStage';

const Wando = () => {
  const [ stage, setStage ] = useState(1);

  const goNextStage = ($event: any) => {
    setStage(stage + 1);
  };

  const isIntro = (): boolean => {
    return stage === 1;
  };

  const isFirstStage = (): boolean => {
    return stage === 2;
  }

  return (
    <React.Fragment>
      { isIntro() && 
        <WandoIntro goNextStage={goNextStage} />        
      }
      { isFirstStage() &&
        <WandoFirstStage goNextStage={goNextStage} />
      }
    </React.Fragment>
  );
};

export default Wando;