import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../shared/reducers";
import logger from 'redux-logger';
import { useDispatch } from "react-redux";

const store = configureStore({
  reducer: rootReducer,
  preloadedState: {}, //ssr
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(logger)
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;
